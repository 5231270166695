/** @deprecated */
const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const getParameterByNameV2 = (name, url = window.location.href) => {
    const requestURL = new URL(url ?? window.location.href);
    const requestID = requestURL.searchParams.get(name);

    return requestID;
};

const isValidURL = (url) => {
    try {
        const validURL = new URL(url);

        if (validURL.protocol !== 'https:') {
            return false;
        }

        return true;
    } catch (e) {
        return false;
    }
};

export { getParameterByName, getParameterByNameV2, isValidURL };
